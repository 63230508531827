@import url(https://fonts.googleapis.com/css?family=M+PLUS+Rounded+1c&display=swap);
body {
  margin: 0;
  font-family: 'M PLUS Rounded 1c', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 20vmin;
  pointer-events: none;
  margin: 20px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-swell 2s ease-in-out;
            animation: App-logo-swell 2s ease-in-out;
    -webkit-animation-direction: alternate;
            animation-direction: alternate;
  }
}

.App-header {
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.App-notice {
  font-weight: normal;
}

.App-copyright {
  font-size: calc(10px + 0.2vmin);
  color: #777e8d;
  position: absolute;
  bottom: 0.2vmin;
  left: 50%;
  transform: translateX(-50%);
}

.Button a {
  text-decoration: none;
  color: #f94a76;
  transition: background-color 100ms, color 100ms;
  border: 1px solid #f94a76;
  border-radius: 5px;
  padding: 10px;
  display: block;
}

.Button a:hover {
  text-decoration: none;
  color: white;
  background-color: #f94a76;
}

@-webkit-keyframes App-logo-swell {
  0% {
    transform: scale(0.6);
    opacity: 0;
  }
  30% {
    transform: scale(1.2);
    opacity: 1;
  }
  100% {
    transform: scale(1.0);
  }
}

@keyframes App-logo-swell {
  0% {
    transform: scale(0.6);
    opacity: 0;
  }
  30% {
    transform: scale(1.2);
    opacity: 1;
  }
  100% {
    transform: scale(1.0);
  }
}

